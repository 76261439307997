<template>
  <div>
    <span class="font-base mr-10"
      >{{ selectedOrders.length }}
      {{ formatServiceUnit("order", selectedOrders.length) }} selected</span
    >
    <el-dropdown :placement="'bottom'" @command="command">
      <el-button type="primary">
        Actions <i class="eden-icon-arrow-down"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(value, key, i) in actions"
          :command="key"
          :key="i"
          >{{ value }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      ref="confirm"
      :show.sync="showMarkConfirmation"
      :title="actions[markFunction]"
      :button-text="'Mark'"
      :button-type="'success'"
      :button-status.sync="marking"
      @confirm="markOrders"
    >
      <p class="font-sm">
        Proceed to mark selected orders as
        <span class="text-bold text-grey-primary">{{
          formatToTitleCase(markFunction.replaceAll("_", " "))
        }}</span
        >?
      </p>
    </eden-confirm-dialog>
  </div>
</template>
<script>
import fulfillment from "@/requests/fulfillment/index";
export default {
  name: "OperationsOrdersActions",
  props: {
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      markReassignSp: true,
      actions: {
        // ready_operations: "Ready",
        in_transit: "In-transit",
        delivered_operations: "Delivered",
        // cancelled_operations: "Cancelled",
        cancelled_fpt: "Cancelled"
      },
      markable: {
        // is_ready_fpt: true,
        is_in_transit: true,
        pickup_rider_delivered: true,
        is_delivered_operations: true,
        is_cancelled_fpt: true,
      },
      selectedOrdersIds: [],
      selectedOrdersWithSp: 0,
      selectedOrdersWithoutTally: 0,
      markFunction: "",
      marking: false,
      showMarkConfirmation: false,
    };
  },
  watch: {
    selectedOrders() {
      this.setSelectedOrders();
    },
  },
  created() {
    this.setSelectedOrders();
  },
  methods: {
    command(command) {
        this.markFunction = command;
        this.showMarkConfirmation = true;
    },
    setSelectedOrders() {
      this.markReassignSp = true;
      this.selectedOrdersIds = [];
      this.selectedOrdersWithSp = 0;
      const markableEvents = Object.keys(this.markable).slice(1);
      markableEvents.forEach((key) => {
        this.markable[key] = true;
      });

      // this.selectedOrders.forEach((order) => {
      //   markableEvents.forEach((key, index) => {
      //     const previousEvent =
      //       index < 1
      //         ? order.pre_sp_assigned.status
      //         : order[markableEvents[index - 1]].status;
      //     const currentEvent = !order[key].status;

      //     this.markable[key] =
      //       previousEvent && currentEvent && this.markable[key];
      //   });

      //   this.selectedOrdersIds.push(order.order_id);
      // });
      this.selectedOrdersIds = this.selectedOrders.map((item) => item.order_id);
    },
    markOrder() {
      this.markOrders();
    },
    markOrders() {
      this.marking = true;
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        order_ids: this.selectedOrdersIds,
        order_status: this.markFunction,
      };
      console.log(payload)
      fulfillment
        .activity( payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            this.showMarkConfirmation = false;
            this.$message.success(message);
            this.marking = false;
            this.$emit("updated");
          } else {
            this.$message.error(message);
            this.$emit("error");
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.$emit("error");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
