<template>
  <div>
    <el-row>
      <el-col :span="24">
        <eden-table-actions :title="title" @search="setQuery">
          <template slot="title"> </template>
          <template slot="actions">
            <template v-if="selectedOrders.length > 0">
              <operations-orders-actions v-show="showSelectedOrders" ref="operationActions"
                :selected-orders="selectedOrders" @error="selectedOrders = []" @updated="getOrders({ period: period })" />
            </template>
            <template v-else>
              <el-row type="fle x" justify="end" :gutter="20">
                <el-col :span="12">
                  <el-button type="plain" icon="eden-icon-upload" class="ml-10" :disabled="loading || !pageData.length"
                    @click="exportAction">
                    Export
                  </el-button>
                </el-col>

                <el-col :span="12">
                  <orders-filter :disabled="loading" :clear="filterParams.clear" @filter="filter" />
                </el-col>
              </el-row>
            </template>
          </template>
        </eden-table-actions>
      </el-col>
      <el-col :span="24">
        <eden-loader v-if="loading" />
        <template v-else>
          <eden-filter-items v-if="filterParams.status" :params="filterParams.paramsLabel" @clear-filter="clearFilter"
            @clear-filters="clearFilters" />
          <template v-if="pageData.length">
            <el-table v-if="pageData.length" :data="pageData" @select-all="setSelectedOrders"
              @selection-change="setSelectedOrders">
              <el-table-column type="selection" width="45"></el-table-column>
              <el-table-column width="100">
                <template #header>
                  <eden-table-column-header :label="'Order ID'" :property="'id'" :sort-property="sort.property"
                    @sort="sortPageData($event, 'id')" />
                </template>
                <template slot-scope="scope">
                  <router-link :to="{
                    name: 'orders.order',
                    params: { id: scope.row.order_id },
                  }">
                    <span class="font-sm text-primary">
                      {{ scope.row.order_id }}</span></router-link>
                </template>
              </el-table-column>
              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Customer'" :property="'customer_name'" :sort-property="sort.property"
                    @sort="sortPageData($event, 'customer_name')" />
                </template>
                <template slot-scope="scope">
                  <div>
                    <span class="font-sm"> {{ scope.row.customer_name }}</span>
                  </div>
                  <div>
                    <span class="font-sm"> {{ scope.row.customer_email }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Customer Address'" :property="'customer_address'"
                    :sort-property="sort.property" @sort="sortPageData($event, 'customer_address')" />
                </template>
                <template slot-scope="scope">
                  <div>
                    <span class="font-sm"> {{ scope.row.customer_address }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Location Area'" :property="'location_area'"
                    :sort-property="sort.property" @sort="sortPageData($event, 'location_area')" />
                </template>
                <template slot-scope="scope">
                  <div>
                    <span class="font-sm"> {{ scope.row.location_area }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column width="350">
                <template #header>
                  <eden-table-column-header :label="'Combo'" :property="'items_assigned_to_order'"
                    :sort-property="sort.property" @sort="sortPageData($event, 'items_assigned_to_order')" />
                </template>
                <template slot-scope="scope">
                  <ul class="meals">
                    <li v-for="(meal, i) in scope.row.items_assigned_to_order" :key="i">
                      {{ formatMealName(meal.specifics) }}
                      <span class="text-bold">X {{ meal.quantity }}</span>
                    </li>
                  </ul>
                </template>
              </el-table-column>

              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Order Date'" :property="'order_date'" :sort-property="sort.property"
                    @sort="sortPageData($event, 'order_date')" />
                </template>
                <template slot-scope="scope">
                  <span class="font-sm">
                    <el-tag type="success" class="text-normalize">
                      {{ formatDateAndTime(scope.row.order_date, "do m,y") }},

                      {{ formatTimeFromNow(scope.row.order_date, true) }}
                    </el-tag>
                  </span>
                </template>
              </el-table-column>

              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Delivery Date'" :property="'delivery_date'"
                    :sort-property="sort.property" @sort="sortPageData($event, 'delivery_date')" />
                </template>
                <template slot-scope="scope">
                  <span class="font-sm">
                    <el-tag type="success" class="text-normalize">
                      {{ formatDate(scope.row.deliver_to_customer, "do m,y") }}
                    </el-tag>
                  </span>
                </template>
              </el-table-column>
              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Phone Number'" :property="'customer_phone_number'"
                    :sort-property="sort.property" @sort="sortPageData($event, 'customer_phone_number')" />
                </template>
                <template slot-scope="scope">
                  <span class="font-sm">
                    {{ scope.row.customer_phone_no }}</span>
                </template>
              </el-table-column>
              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Ready'" :property="'ready'" :sort-property="sort.property"
                    @sort="sortPageData($event, 'ready')" />
                </template>
                <template v-slot="scope">
                  <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'ready_fpt'"
                    :function-status="!!scope.row.is_ready_fpt" :function-time="scope.row.is_ready_fpt_time"
                    :function-actor="scope.row.is_ready_fpt_by" @action="setSelectedOrder(scope.row, 'ready_fpt')" />
                </template>
              </el-table-column>

              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'In Transit'" :property="'transit'" :sort-property="sort.property"
                    @sort="sortPageData($event, 'transit')" />
                </template>
                <template v-slot="scope">
                  <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'in_transit'"
                    :function-status="!!scope.row.is_in_transit" :function-time="scope.row.is_in_transit_time"
                    :function-actor="scope.row.is_in_transit_by" @action="setSelectedOrder(scope.row, 'in_transit')" />
                </template>
              </el-table-column>

              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Delivered'" :property="'deliver'" :sort-property="sort.property"
                    @sort="sortPageData($event, 'deliver')" />
                </template>
                <template v-slot="scope">
                  <order-function-status :period="period" :allow-marking="allowMarking"
                    :mark-function="'delivered_operations'" :function-status="!!scope.row.is_delivered_operations"
                    :function-time="scope.row.is_delivered_operations_time"
                    :function-actor="scope.row.is_delivered_operations_by"
                    @action="setSelectedOrder(scope.row, 'delivered_operations')" />
                </template>
              </el-table-column>

              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header :label="'Cancelled'" :property="'cancel'" :sort-property="sort.property"
                    @sort="sortPageData($event, 'cancel')" />
                </template>
                <template v-slot="scope">
                  <order-function-status :period="period" :allow-marking="allowMarking" :mark-function="'cancelled_fpt'"
                    :function-status="!!scope.row.is_cancelled_fpt" :function-time="scope.row.is_cancelled_fpt_time"
                    :function-actor="scope.row.is_cancelled_fpt_by"
                    @action="setSelectedOrder(scope.row, 'cancelled_fpt')" />
                </template>
              </el-table-column>
            </el-table>
            <eden-pagination v-if="showPagination" :from="from" :to="to" :total="total" :current-page.sync="page" />
          </template>
          <eden-content-empty v-else :text="'No orders'" />
        </template>
      </el-col>
    </el-row>
    <fulfillment-export :show.sync="showExportFulfillment" :data-to-export="exportData"
      :stringed-filter="stringedParams" />
  </div>
</template>

<script>
import OperationsOrdersActions from "@/components/MFC/OperationsOrdersActions.vue";
import OrderFunctionStatus from "@/components/MFC/OrderFunctionStatus";
import OrdersFilter from "@/components/MFC/OrdersFilter.vue";
import * as actions from "@/store/action-types";
import fulfillment from "@/requests/fulfillment/index";
import FulfillmentExport from "@/components/MFC/FulfillmentExport";

export default {
  components: {
    OperationsOrdersActions,
    OrdersFilter,
    OrderFunctionStatus,
    FulfillmentExport
  },
  data() {
    return {
      loading: false,
      showExport: false,
      showExportFulfillment: false,
      exporting: false,
      pageData: [],
      exportData: [],
      period: "today",
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
      custom: {
        from: null,
        to: null,
      },
      showPagination: true,
      page: 1,
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      sort: {
        property: "name",
        direction: "asc",
      },
      stringedParams: "",
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      summary: {
        loading: false,
        data: {},
      },
      selectedOrders: [],
    };
  },
  computed: {
    allowMarking() {
      return this.allowEventMarking(this.period, ["gardener", "admin", "operations", "superadmin", "gardener_pro"]);
    },
    orders() {
      return this.$store.getters.fulfillment_orders_schedule.meal;
    },
    from() {
      return this.orders.pages[this.page].from;
    },
    to() {
      return this.orders.pages[this.page].to;
    },
    total() {
      return this.orders.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      const length = this.showPagination ? total || 0 : result;
      return `${length} Order${length > 1 ? "s" : ""}`;
    },
  },
  watch: {
    page() {
      this.getOrders({
        period: this.period,
        from: this.custom.from,
        to: this.custom.to,
      });
    },
    total(newTotal, oldTotal) {
      console.log(`Total orders changed from ${oldTotal} to ${newTotal}`);
    },
  },
  created() {
    this.getOrders({
      period: this.period,
    });
  },
  methods: {
    setQuery(query) {
      if (query === "") {
        this.getOrders({
          period: this.period,
        });
        return;
      }
      this.loading = true;
      let params = this.composeParams(query);
      fulfillment
        .search(params)
        .then(this.handleSearchSuccess)
        .catch(this.handleSearchError)
        .finally(() => {
          this.loading = false;
        });
    },

    composeParams(query) {
      if (this.stringedParams !== "") {
        return `${query}?${this.stringedParams}`;
      } else {
        const start_date = this.custom.from;
        const end_date = this.custom.to;
        return `${query}?service=meal&start_date=${start_date}&end_date=${end_date}`;
      }
    },

    handleSearchSuccess(response) {
      const { data } = response.data.data;
      this.pageData = data;
      this.exportData = this.pageData;
      this.showPagination = false;
    },

    handleSearchError(error) {
      const errorMessage = error.response.data;
      if (errorMessage.errors) {
        const firstErrorKey = Object.keys(errorMessage.errors)[0];
        const firstErrorMessage = errorMessage.errors[firstErrorKey].join("");
        this.$message.error(firstErrorMessage);
      } else {
        this.$message.error(errorMessage.message || "An error occurred.");
      }
    },
    getOrders({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      this.custom.from = start_date;
      this.custom.to = end_date;
      this.loading = true;
      this.$store
        .dispatch(actions.GET_FULFILLMENT_ORDERS_SCHEDULE, {
          service: "meal",
          start_date: this.custom.from,
          end_date: this.custom.to,
          page: this.page,
        })
        .then(() => {
          // this.mixpanelTrack({
          //   event: this.allowMarking
          //     ? `order_fulfillment_filter_${period}`
          //     : `order_schedule_meal_${period}`,
          // });
          this.period = period;
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.orders?.pages[this.page]?.data;
      // this.pageData = formatMealData(this.pageData);
      this.showPagination = true;
      this.pageDataTime = new Date();
    },
    setSelectedOrders(orders) {
      this.selectedOrders = orders;
      this.showSelectedOrders = !!orders.length;
    },
    setSelectedOrder(order, markFunction) {
      this.selectedOrders.push(order);
      setTimeout(() => {
        this.$refs.operationActions.markFunction = markFunction;
        this.$refs.operationActions.markOrder();
      }, 5);
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.orders = this.sortList(this.orders, property, direction);
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      let stringedParams = this.sortObjectAsParams(params) + "&service=meal";
      this.page = 1;
      this.filterParams = {
        status: true,
        params: { ...this.filterParams.params, ...params },
        paramsLabel: { ...this.filterParams.paramsLabel, ...paramsLabel },
      };
      stringedParams = stringedParams.replace(/\[\]/g, "");
      this.stringedParams = stringedParams;
      fulfillment
        .filter(this.stringedParams)
        .then((response) => {
          this.filterParams = {
            status: true,
            params: params,
            paramsLabel: paramsLabel,
          };
          this.pageData = response.data?.data?.data;
          // this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];
      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getOrders({
        period: this.period,
      });
    },
    exportAction() {
      if (this.showPagination) {
        this.exportData = this.pageData;
      }
      this.showExportFulfillment = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  &:not(:last-child) {
    margin-bottom: 50px !important;
  }
}

.meals {
  margin: 0;
  padding-left: 10px;
  list-style-type: circle;
  word-break: break-word;

  li {
    max-width: 250px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  span {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
